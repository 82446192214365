/**
 * Print a debugging statement to the console if running in development environment.
 *
 * @param  {...any} args The arguments to pass on to console.debug.
 */
export function printToConsole(...args) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.debug(...args);
  }
}
