import $ from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete';

import { Map, Overlay, View } from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import Vector from 'ol/source/Vector';

import {
  pixelkarte_farbe_wmts,
  pixelkarte_grau_wmts,
} from './layer/swisstopo_layer';
import {
  baseLayers,
  geltscher_maechtigkeit,
  glamosSgi,
  glamosSgiFactsheet,
  gletscher_nodata,
  gletscher_source_haslength,
  gletscher_source_hasmass,
  gletscher_source_nodata,
  GletscherLayers,
  selectedOverlay,
  switcher,
} from './layers';
import { hidePoints, hoverStyle, hoverStyleSmall } from './styles';

import controller from '../controller';
import urlManager from '../UrlManager';

// highlightedGlacier: the one feature (glacier) which is selected
// selectedGlaciers: list of features (glaciers) for comparison
import datastore from '../datastore';
import { convertMmToM } from '../helpers';

const DISPLAY_NAME = 'glacier_full_name';
const GLETSCHER_MAECHTIGKEIT_LEGEND_ID = 'gletschermaechtigkeit-legend';

const format = new GeoJSON();
const url = '/geo/inventory/web_glacier_base_data.geojson';

const tooltip = document.createElement('div');
tooltip.classList.add('map-tooltip'); // apply css via class
document.body.append(tooltip);

export default {
  initMap() {
    const featureHasData = function (feature) {
      return feature.get('has_mass') || feature.get('has_length');
    };

    // https://stackoverflow.com/a/53849880/2652567
    const htmlencode = function (str) {
      const div = document.createElement('div');
      div.appendChild(document.createTextNode(str));
      return div.innerHTML;
    };

    const thin_nbsp = ' ';

    const concat_range = function (elements) {
      const en_dash = '–';
      return elements.join(thin_nbsp + en_dash + thin_nbsp);
    };

    const format_span = function (dates) {
      const abbrs = [];
      // Turn strings of the form "2019-01-22" into <abbr> tags
      // with content "2019" and title "22.01.2019".
      for (const date of dates) {
        /*
        If any of the dates are not strings, a default dash is returned instead.
        This is because there's no need for a result that looks like this "2010 - " or " - 2018"
        */
        if (typeof date !== 'string') return '-';
        const parts = date.split('-');
        const year = htmlencode(parts[0]);
        parts.reverse();
        const str = htmlencode(parts.join('.'));
        abbrs.push(`<abbr title="${str}">${year}</abbr>`);
      }
      return concat_range(abbrs);
    };

    const format_plain = function (text) {
      return htmlencode(text);
    };

    class InfoboxField {
      constructor(className, format) {
        this.selector = `.${className}`;
        this.format = format;
      }

      clear() {
        const em_dash = '—';
        $(this.selector).text(em_dash);
      }

      update(value) {
        $(this.selector).html(this.format(value));
      }
    }

    const fillSchluesseldaten = function (feature) {
      const glacierName = new InfoboxField('infobox-glaciername', format_plain);
      glacierName.update(feature.get(DISPLAY_NAME));

      // area_m2 should be displayed as long as it's not undefined or null aka "falsy" (0 is allowed)
      const area_m2 = feature.get('area_m2');
      if (area_m2 || area_m2 === 0) {
        const areaChange = new InfoboxField(
          'infobox-area--change',
          format_plain
        );
        // using format_plain since we don't want format_span's <abbr> markup here
        const areaTimespan = new InfoboxField(
          'infobox-area--timespan',
          format_plain
        );
        areaChange.update((feature.get('area_m2') / 1000 / 1000).toFixed(2));
        areaTimespan.update(feature.get('area_year'));
      }

      const massChange = new InfoboxField('infobox-mass--change', format_plain);
      const massTimespan = new InfoboxField(
        'infobox-mass--timespan',
        format_span
      );
      if (feature.get('has_mass')) {
        const from = feature.get('last_mass_balance_observation_from');
        const to = feature.get('last_mass_balance_observation_to');
        massTimespan.update([from, to]);
        const massBalance = feature.get('last_mass_balance_observation');
        massChange.update(convertMmToM(Math.round(massBalance)));
      } else {
        massChange.clear();
        massTimespan.clear();
      }

      const lengthChange = new InfoboxField(
        'infobox-length--change',
        format_plain
      );
      // using format_plain since we don't want format_span's <abbr> markup here
      const lengthTimespan = new InfoboxField(
        'infobox-length--timespan',
        format_plain
      );
      if (feature.get('has_length')) {
        const from = feature.get('first_year_length');
        const to = feature.get('last_year_length');
        const lengthChangeCumulative = feature.get(
          'last_length_change_cumulative'
        );
        lengthTimespan.update(concat_range([from, to]));
        lengthChange.update(Math.round(lengthChangeCumulative));
      } else {
        lengthChange.clear();
        lengthTimespan.clear();
      }
    };

    /**
     * Monitoring: Selection List
     */
    class SelectionList {
      constructor(datastoreList) {
        this.store = datastoreList;
        const path =
          'M305.5,256,473.75,87.75a35,35,0,0,0-49.5-49.5L256,206.5,87.75,38.25a35,35,0,0,0-49.5,49.5L206.5,256,38.25,424.25a35,35,0,0,0,49.5,49.5L256,305.5,424.25,473.75a35,35,0,0,0,49.5-49.5Z';
        this.svgClose = `<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title>close</title>
            <path d="${path}"></path>
          </svg>`;

        this.reset = this.reset.bind(this);
        this.renderEntry = this.renderEntry.bind(this);

        /* Hook up reset callback only once (thus done in contructor) */
        $('#monitoring-glacier--list + button[name="reset"]').on(
          'click',
          this.reset
        );
      }

      select(id) {
        const pureId = id.replace('--list', '');
        controller.selectionListHighlight(pureId);
        this.refresh();
      }

      remove(id) {
        const pure_id = id.replace('--close', '');
        controller.selectionListRemove(pure_id);
        this.refresh();
      }

      reset() {
        controller.selectionListReset();
        this.refresh();
      }

      /* TODO: Implement reset via button */

      refresh() {
        if (!this.store) return;
        const contents = this.store.features.map(this.renderEntry);
        $('#monitoring-glacier--list')
          .html(contents)
          .find('[name="highlight"]')
          .on('click', (ev) => this.select(ev.currentTarget.id))
          .end()
          .find('[name="remove"]')
          .on('click', (ev) => this.remove(ev.currentTarget.id))
          .end();
        $('#selectionlist-max-warn').toggleClass(
          'hidden',
          !this.store.maxEntriesReached()
        );
      }

      renderEntry(feature) {
        // LEGACY not sure strict comparison works here
        // eslint-disable-next-line eqeqeq
        const auxClass =
          feature == datastore.highlightedGlacier.feature ? 'active' : '';
        const id = feature.getId();
        const name = feature.get(DISPLAY_NAME);
        return `<div class="comparisonEntry ${auxClass}">
            <button type="button" name="highlight" class="glacierName" id="${id}--list">${name}</button>
            <button type="button" name="remove" class="btn close" id="${id}--close">
              ${this.svgClose}
            </button>
          </div>`;
      }

      denyAddition() {
        const el = $('#selectionlist-max-warn').addClass('toast');
        setTimeout(() => el.removeClass('toast'), 1000);
      }
    }

    const monitoringSelectedFeatureList = new SelectionList(
      datastore.selectedGlaciers
    );
    controller.bridge({ monitoringSelectedFeatureList });

    /* ----- */

    function dynamicLinks() {
      $('a.js-keephash').on('click', function (e) {
        urlManager.navigateTo(this.href);
        e.preventDefault();
      });
    }
    controller.bridge({ dynamicLinks });

    /*  Search Bar
     *
     * Used on the tabs Home, Monitoring and Factsheet
     * This function bootstratps the search bar:
     * - sets up the underlaying data
     * - attaches autocomplete to the template's input
     * - handles the select event (choosing a hit)
     */
    const enableSearch = function (gletscher_features) {
      const searchInput = $('.fieldSearchWrapper input');

      const filteredFeatures = gletscher_features.filter(featureHasData);
      if (!searchInput.length || !filteredFeatures.length) return;

      const searchData = filteredFeatures.map((feat) => ({
        label: feat.get(DISPLAY_NAME),
        value: feat,
      }));

      /**
       * Keep search bar empty
       *
       * (otherwise ui.item.value shows up in <input> as "[object Object]")
       */
      const preventInputPopulation = function (ev) {
        /*
         * from jQuery-UI docs:
         * Cancelling (focus|select) event prevents input from being updated
         */
        ev.preventDefault();
      };

      const onSelect = function (ev, ui) {
        const feature = ui.item.value;
        controller.searchSelected(feature);
        preventInputPopulation(ev);

        /* Remove user-typed search string */
        $(ev.target).val('');
      };

      searchInput.autocomplete({
        minLength: 2,
        source: searchData,
        focus: preventInputPopulation,
        select: onSelect,
      });
    };
    controller.bridge({ enableSearch });

    /* 3 Map instances, one for each tab */
    let map = null;

    const mapDefaults = {
      resolutions: [
        500, 229.31, 100, 57.327, 28.663, 14.331, 7.165, 3.582, 1.791, 0.8955,
      ],
      extent: [650000, 4000000, 1200000, 6500000],
      center: [903280, 5913450],
    };

    if (document.getElementById('factsheet-map')) {
      /* Only one map layer, static map centered on glacier (dynamically set) */
      map = new Map({
        target: 'factsheet-map',
        extent: [650000, 4000000, 1200000, 6500000],
        layers: [pixelkarte_grau_wmts, glamosSgiFactsheet, gletscher_nodata],
        interactions: [], // remove all interactions like zoom, pan etc. for factsheetwindow
        controls: [], // remove zoom for factsheetwindow
        view: new View({
          extent: mapDefaults.extent,
          center: mapDefaults.center,
          resolutions: mapDefaults.resolutions,
          resolution: 28.663,
        }),
      });

      pixelkarte_grau_wmts.set('visible', true);
      gletscher_nodata.setStyle(hidePoints);
    } else if (document.getElementById('monitoring-map')) {
      map = new Map({
        target: 'monitoring-map',
        layers: [
          baseLayers,
          glamosSgi,
          geltscher_maechtigkeit,
          GletscherLayers,
        ],
        view: new View({
          extent: mapDefaults.extent,
          center: mapDefaults.center,
          resolutions: mapDefaults.resolutions,
          resolution: 57.327,
        }),
      });

      pixelkarte_farbe_wmts.set('visible', true);
      map.addControl(switcher);
    } else if (document.getElementById('home-map')) {
      /* Only one map layer → no layer switcher */
      map = new Map({
        target: 'home-map',
        layers: [pixelkarte_farbe_wmts, GletscherLayers],
        view: new View({
          extent: mapDefaults.extent,
          center: mapDefaults.center,
          resolutions: mapDefaults.resolutions,
          resolution: 100,
        }),
      });
      pixelkarte_farbe_wmts.set('visible', true);
    }

    if (map) {
      map.addLayer(selectedOverlay);
    }

    /* Add interactivity to the map */

    /**
     * Get all features under the pointer
     * @param  {Event} browserEvent
     * @return {Array}
     * @depends map
     */
    const mouse2features = function (browserEvent) {
      const { coordinate } = browserEvent;
      const pixel = map.getPixelFromCoordinate(coordinate);
      const features = [];
      map.forEachFeatureAtPixel(pixel, (feature) => features.push(feature));
      return features;
    };

    /**
     * Pan the map to the given feature
     *
     * @param  {Object} feature
     * @depends map
     */
    const mapPanTo = function (feature) {
      if (!feature || !map) return;
      const center = [feature.get('coordx'), feature.get('coordy')];
      map.getView().setCenter(center);
    };
    controller.bridge({ mapPanTo });

    /**
     * Populate Schluesseldaten, highlight selected marker
     *
     * @param  {Object} feature [description]
     * @depends highlightedGlacier, selectedOverlay, fillSchluesseldaten
     */
    const selectGlacier = function (feature) {
      if (!feature) return;

      /* 0. Store the feature as the highlighted one */
      datastore.highlightedGlacier.feature = feature;

      /* 1. Fill infobox from feature */
      fillSchluesseldaten(feature);

      /* 2a. Reset current selection */
      selectedOverlay.getSource().clear();

      /* 2. fuege roten Marker (selektierter Gletscher) als Overlay hinzu */
      selectedOverlay.getSource().addFeature(feature);
    };

    /*
     * Select feature when the user clicks it
     *
     * (last one in DOM if pointer is above multiple features)
     */
    const onMapClick = function (browserEvent) {
      let features = mouse2features(browserEvent);

      /* consider only glaciers with data */
      features = features.filter(featureHasData);
      if (!features.length) return;

      /*
       * Manche Gletscherpunkte sind so dicht zusammen, dass mehr als einer gelesen wird;
       * es wird nur das letzte Feature beachtet
       */
      controller.mapMarkerHighlighted(features[features.length - 1]);
    };
    controller.bridge({ selectGlacier });

    if (map) map.on('click', onMapClick);

    /* Add hover style */
    const hoverOverlay = new VectorLayer({
      source: new Vector(),
      style(_feature, resolution) {
        if (resolution > 100) {
          return hoverStyleSmall;
        }
        return hoverStyle;
      },
    });

    const tooltipOverlay = new Overlay({
      element: tooltip,
      offset: [10, 0],
      positioning: 'bottom-left',
    });
    if (map) map.addOverlay(tooltipOverlay);

    let hover;

    /**
     * @param  {Object} pixel
     * @return {Object|boolean} [TODO: description]
     * @depends map, hoverOverlay, hover
     */
    const featureHover = function (pixel) {
      let featureAtPixel = null;
      map.forEachFeatureAtPixel(pixel, function (feature) {
        featureAtPixel = feature;
        return true;
      });

      // Hover only works if glacier has data
      if (
        featureAtPixel &&
        featureHasData(featureAtPixel) &&
        featureAtPixel !== hover &&
        featureAtPixel !== datastore.highlightedGlacier.feature
      ) {
        if (hover) {
          hoverOverlay.getSource().removeFeature(hover);
        }
        if (featureAtPixel) {
          hoverOverlay.getSource().addFeature(featureAtPixel);
        }
        hover = featureAtPixel;
      }
    };

    if (map) {
      /* Add “hand” pointer */
      map.on('pointermove', function (e) {
        if (e.dragging) return;

        const pixel = map.getEventPixel(e.originalEvent);
        let featureAtPixel = null;
        map.forEachFeatureAtPixel(pixel, (feature) => {
          featureAtPixel = feature;
          return true;
        });
        const hit = featureAtPixel ? featureHasData(featureAtPixel) : false;

        let display = 'none';
        if (featureAtPixel) {
          tooltipOverlay.setPosition(e.coordinate);
          const glacierName = featureAtPixel.get('glacier_full_name');
          display = glacierName ? 'block' : display;
          const suffixes = [];
          if (featureAtPixel.get('has_mass')) suffixes.push('MB');
          if (featureAtPixel.get('has_length')) suffixes.push('dL');
          const strSuffix = suffixes.length ? `(${suffixes.join(', ')})` : '';
          tooltip.innerText = `${glacierName} ${strSuffix}`;
        }
        tooltip.style.display = display;

        map.getTargetElement().style.cursor = hit ? 'pointer' : '';
        featureHover(pixel);
      });
    }

    this.addColorlegend(switcher);
    // display custom gletschermaechtigkeit image legend only when the layer is turned on
    geltscher_maechtigkeit.on('change:visible', (event) => {
      const legend = document.getElementById(GLETSCHER_MAECHTIGKEIT_LEGEND_ID);
      legend.style.display = event.target.getVisible() ? 'block' : 'none';
    });
  },

  /**
   * Fetch glacier base data features.
   * @returns {Promise<Array<Object>>}
   */
  async fetchFeatures() {
    const response = await $.ajax(url);
    return format.readFeatures(response, {
      featureProjection: 'EPSG:3857',
    });
  },

  /**
   * Fetch the glacier features and load them into the datastore.
   * @returns {Promise<void>}
   */
  async loadFeatures() {
    datastore.features.set(await this.fetchFeatures());
  },

  addFeaturesToMap(features) {
    // store features in 3 different vectorsources to filter in layerswitcher
    for (let i = 0; i < features.length; i += 1) {
      const feature = features[i];
      let got_data = false;
      if (feature.get('has_mass')) {
        gletscher_source_hasmass.addFeature(feature);
        got_data = true;
      }

      if (feature.get('has_length')) {
        gletscher_source_haslength.addFeature(feature);
        got_data = true;
      }

      if (!got_data) {
        gletscher_source_nodata.addFeature(feature);
      }
    }

    return features;
  },

  /**
   * Append a colored indicator div to each layer in the switcher which has a
   * defined color.
   *
   * @param {ol-ext.LayerSwitcher} layerSwitcher
   */
  addColorlegend(layerSwitcher) {
    // Listen on drawlist in order to have access to the rendered DOM elements.
    layerSwitcher.on('drawlist', function (e) {
      // The color to be used is defined on the layer itself and can be any valid
      // CSS color.
      const color = e.layer.values_.layerSwitcherColor;
      if (color) {
        const elLabel = e.li.querySelector('label');
        const colorlegendClassName = 'colorlegend';

        // Only add a colorlegend for this layer if there's none yet.
        if (!elLabel.querySelector(`.${colorlegendClassName}`)) {
          const elColorlegend = document.createElement('div');
          elColorlegend.classList.add(colorlegendClassName);
          elColorlegend.style.backgroundColor = color;
          elLabel.insertBefore(elColorlegend, elLabel.firstChild);
        }
      }
      const customImage = e.layer.values_.layerSwitcherCustomImage;
      if (customImage) {
        const elLabel = e.li.querySelector('label span');
        const customImageClassName = 'legend-custom-image';
        const elImgContainer = elLabel.parentElement.parentElement;

        // Only add a legend for this layer if there's none yet.
        if (!elImgContainer.querySelector(`.${customImageClassName}`)) {
          const elLegendImg = document.createElement('img');
          elLegendImg.id = GLETSCHER_MAECHTIGKEIT_LEGEND_ID;
          elLegendImg.classList.add(customImageClassName);
          elLegendImg.style.display = geltscher_maechtigkeit.getVisible()
            ? 'block'
            : 'none';
          elLegendImg.src = customImage;
          elImgContainer.appendChild(elLegendImg);
        }
      }
      const icon = e.layer.values_.layerSwitcherIcon;
      if (icon) {
        const elLabel = e.li.querySelector('label span');
        const iconClassName = 'legend-icon';

        // Only add a colorlegend for this layer if there's none yet.
        if (!elLabel.querySelector(`.${iconClassName}`)) {
          const elIconImg = document.createElement('img');
          elIconImg.classList.add(iconClassName);
          elIconImg.src = icon;
          elLabel.insertBefore(elIconImg, elLabel.firstChild);
        }
      }
    });
  },
};
