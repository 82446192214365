import Chart from './Chart';
import ChartDownloadControls from './ChartDownloadControls';

export default {
  /**
   * Create a chart and draw it.
   *
   * @param {HTMLElement} container
   * @param {GlacierInfo[]} glacierInfos
   * @param {Object} options
   * @param {string} options.legend
   * @param {string} options.source
   * @param {boolean} options.isCumulative
   * @param {string} options.unit
   * @param {boolean} options.showNames
   * @param {Function} beforeShow
   * @return The Chart instance.
   */
  update(container, glacierInfos, options, beforeShow) {
    const chart = new Chart(container, glacierInfos, options);
    chart.draw(beforeShow);
    return chart;
  },

  /**
   * Update a chart's download controls.
   *
   * @param {Chart} chart The chart to be downloaded.
   * @param {boolean} isGlobal Whether the controls are global.
   */
  updateDownloadControls(chart, isGlobal) {
    const chartDownloadControls = new ChartDownloadControls(chart, isGlobal);
    if (isGlobal) chartDownloadControls.updateChartId();
    chartDownloadControls.updateEventListeners();
  },
};
