import { convertMmToM } from '../helpers';

/**
 * @typedef GlacierChartData
 * @property {number} variation
 * @property {number} variation_cumulative
 * @property {number} year_from
 * @property {number} year_to
 * @property {string} date_from
 * @property {string} date_to
 */

/**
 * @typedef GlacierPictures
 * @property {string} filename
 * @property {boolean} is_factsheet_picture
 * @property {string} legend
 */

/**
 * @typedef GlacierInfoJson
 * @property {string} uuid
 * @property {string} glacier_full_name
 * @property {string} glacier_short_name
 * @property {GlacierChartData} [length_change]
 * @property {GlacierChartData} [mass_balance_observation]
 * @property {GlacierChartData} [mass_balance_fix_date]
 * @property {GlacierChartData} [mass_balance_fix_date_winter]
 * @property {GlacierChartData} [mass_balance_observation_winter]
 * @property {GlacierPictures} pictures
 * @property {string} pk_sgi
 * @property {Array<Object<string, string>>} texts
 */

/**
 * Apply the conversion if available, else just return the received data.
 *
 * @param {Number} value The value to be converted.
 * @param {Function | null} converter The converter function.
 */
function convert(value, converter) {
  return converter ? converter(value) : value;
}

/**
 * Map chart data structure to normalized keys and
 * (optionally) apply a conversion to the data values.
 *
 * @param {GlacierChartData} data The data to be normalized.
 * @param {Function} [converter] The converter function.
 * @returns {Object | null} The normalized data or null.
 */
function normalizeData(data, converter = null) {
  if (!data) return null;

  return data.map((x) => ({
    variation: convert(x.variation, converter),
    variationCumulative: convert(x.variation_cumulative, converter),
    yearFrom: x.year_from,
    yearTo: x.year_to,
    dateFrom: x.date_from,
    dateTo: x.date_to,
  }));
}

/**
 * Calculate the glacier info's year range across all data.
 *
 * @param  {...Object[]} normalizedDataArrays The data arrays to include.
 * @returns {{from: number, to: number}} The glacier info's year range.
 */
function calculateYearRange(...normalizedDataArrays) {
  let from = null;
  let to = null;
  normalizedDataArrays.forEach((normalizedData) => {
    if (normalizedData !== null) {
      normalizedData.forEach((x) => {
        if (!from || x.yearFrom < from) from = x.yearFrom;
        if (!to || x.yearTo > to) to = x.yearTo;
      });
    }
  });

  return { from, to };
}

/**
 * The glacier info model.
 */
class GlacierInfo {
  /**
   * Create a new GlacierInfo instance.
   *
   * @param {string} uuid
   * @param {GlacierInfoJson} data
   * @returns {GlacierInfo}
   */
  constructor(uuid, data) {
    this.uuid = uuid;
    this.fullName = data.glacier_full_name;
    this.shortName = data.glacier_short_name;

    this.lengthChange = normalizeData(data.length_change);
    this.massBalanceObservation = normalizeData(data.mass_balance_observation);
    this.massBalanceFixDate = normalizeData(data.mass_balance_fix_date);
    this.massBalanceFixDateWinter = normalizeData(
      data.mass_balance_fix_date_winter
    );
    this.massBalanceObservationWinter = normalizeData(
      data.mass_balance_observation_winter
    );
    this.massBalanceObservationInM = normalizeData(
      data.mass_balance_observation,
      convertMmToM
    );
    this.massBalanceFixDateInM = normalizeData(
      data.mass_balance_fix_date,
      convertMmToM
    );
    this.massBalanceFixDateWinterInM = normalizeData(
      data.mass_balance_fix_date_winter,
      convertMmToM
    );
    this.massBalanceObservationWinterInM = normalizeData(
      data.mass_balance_observation_winter,
      convertMmToM
    );
    this.yearRange = calculateYearRange(
      this.lengthChange,
      this.massBalanceFixDate,
      this.massBalanceFixDateWinter,
      this.massBalanceObservation,
      this.massBalanceObservationWinter
    );

    this.pictures = data.pictures
      ? data.pictures.map((val) => {
          return {
            filename: val.filename,
            isFactsheetPicture: val.is_factsheet_picture,
            legend: val.legend,
          };
        })
      : null;
    this.pkSgi = data.pk_sgi;
    this.texts = data.texts
      ? data.texts.map((val) => {
          return {
            citation: val.citation,
            description: val.description,
            language: val.language,
            'special event': val['special event'],
            sources: val.sources,
          };
        })
      : null;
  }
}

export default GlacierInfo;
