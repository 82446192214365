import datastore from '../datastore';
import GlacierInfo from './GlacierInfo';

// Where the glacier info json files live.
const JSON_BASE = '/geo/glacier_infos';

export default {
  /**
   * Fetch the glacier info and create a GlacierInfo model instance.
   * @param {string} uuid
   * @returns {Promise<GlacierInfo>} The GlacierInfo instance.
   */
  async fetchGlacierInfo(uuid) {
    const url = `${JSON_BASE}/${uuid}.json`;
    let data;
    try {
      data = await $.ajax(`${JSON_BASE}/${uuid}.json`);
    } catch (error) {
      throw new Error(
        `${error.status} ${error.statusText}. Could not fetch GlacierInfo from ${url}`
      );
    }
    return new GlacierInfo(uuid, data);
  },

  /**
   * Load glacier info for selected glacier features and load them into the
   * datastore.
   * @returns {Promise<void>}
   */
  async loadGlacierInfos() {
    const promises = datastore.selectedGlaciers.features.map(
      async (feature) => {
        const uuid = feature.get('pk_glacier');
        if (!datastore.glacierInfos.findByUuid(uuid)) {
          datastore.glacierInfos.add(await this.fetchGlacierInfo(uuid));
        }
      }
    );

    // make sure everything has been fetched before resolving the promise
    await Promise.all(promises);
  },

  /**
   * Get the GlacierInfo instances for the selected glaciers.
   * @returns {GlacierInfo[]}
   */
  glacierInfosForSelected() {
    return datastore.selectedGlaciers.features.map((feature) => {
      return datastore.glacierInfos.findByUuid(feature.get('pk_glacier'));
    });
  },
};
