'use strict';

import urlManager from './UrlManager';
import datastore from './datastore';

import { setup } from './factsheetDetails';
import dataview from './dataview';
import MapService from './map/MapService';
import GlacierService from './glacier/GlacierService';
import { printToConsole } from './debug';

/* Constants */

/* Variables */

/* store references to functions that should be moved herein */
const bridge = {};

/* Helpers */

const factsheetUpdate = function (feature) {
  if ('factsheet' === datastore.currentPage) {
    setup(
      feature,
      datastore.glacierInfos.findByUuid(feature.get('pk_glacier'))
    );
  }
};

/** Our Controller (Action → Reaction) */

class Controller {
  _bootstrapFromState() {
    if (datastore.downloadTab) {
      bridge.selectDownloadTab(datastore.downloadTab);
    }
    const { feature } = datastore.highlightedGlacier;
    if (feature) {
      bridge.selectGlacier(feature);
      bridge.mapPanTo(feature);
      this.updateDataview();

      factsheetUpdate(feature);
    }
    bridge.monitoringSelectedFeatureList.refresh();
  }

  _chooseRandom() {
    const feature = datastore.features.getRandomVIG();
    if (feature) {
      this._extendSelection(feature);
      bridge.mapPanTo(feature);
    }
  }

  /**
   * sets some state bits to defaults if they're empty
   */
  _setFallbackState() {
    let needsUpdate = false;

    // default to random glacier
    if (!datastore.highlightedGlacier.get()) {
      this._chooseRandom();
      needsUpdate = true;
    }

    // default to first Download-Tab
    if (!datastore.downloadTab && 'downloads' === datastore.currentPage) {
      const allTabs = bridge.getAvailableDownloadTabs();
      this.changeDownloadTab(allTabs[0]);
      needsUpdate = true;
    }

    if (needsUpdate) {
      // TODO: move to helper since linked
      urlManager.minorUpdate();
      bridge.dynamicLinks();
    }
  }

  /* Init */

  async onPageLoad() {
    await MapService.loadFeatures();
    urlManager.loadState();
    await GlacierService.loadGlacierInfos();

    this.setupDataview();
    MapService.initMap();
    this.gotFeatures(datastore.features.getAll());
    MapService.addFeaturesToMap(datastore.features.getAll());

    bridge.dynamicLinks();
    urlManager.observeHistory();
  }

  onNavigate() {
    this.onPageLoad(); /* just alias */
  }

  gotFeatures(features) {
    this._setFallbackState();
    this._bootstrapFromState();
    bridge.enableSearch(features);
  }

  /* Home */

  mapMarkerHighlighted(feature) {
    this._extendSelection(feature);
    /* note: no map panning */
    urlManager.majorUpdate();
  }

  async searchSelected(feature) {
    bridge.mapPanTo(feature);
    await this._extendSelection(feature);
    factsheetUpdate(feature);
    urlManager.majorUpdate();
  }

  async _extendSelection(feature) {
    if (datastore.selectedGlaciers.maxEntriesReached()) {
      bridge.monitoringSelectedFeatureList.denyAddition(feature);
    } else {
      /* Only Monitoring (aka Mapviewer) allows multiple glaciers. */
      if ('mapviewer' !== datastore.currentPage) {
        datastore.selectedGlaciers.clear();
      }
      datastore.selectedGlaciers.add(feature);

      bridge.selectGlacier(feature);
      bridge.monitoringSelectedFeatureList.refresh();

      await GlacierService.loadGlacierInfos();

      this.updateDataview();
      factsheetUpdate(feature);
    }
  }

  /* Monitoring */

  selectionListHighlight(id) {
    const feature = datastore.selectedGlaciers.findById(id);
    bridge.selectGlacier(feature);
    bridge.mapPanTo(feature);
    urlManager.minorUpdate();
  }

  selectionListRemove(id) {
    datastore.selectedGlaciers.remove(id);
    this.updateDataview();

    /* Select last entry in selected glaciers list */
    urlManager.majorUpdate();
  }

  selectionListReset() {
    datastore.selectedGlaciers.clear();
    this._chooseRandom();
    urlManager.majorUpdate();
  }

  switchChartType() {
    this.updateDataview();

    /* TODO: Update URL */
  }

  // -- Downloads

  changeDownloadTab(tabId) {
    datastore.downloadTab = tabId;
    bridge.selectDownloadTab(tabId);
    urlManager.minorUpdate();
  }

  // -- Helpers

  /**
   * Setup the dataview.
   */
  setupDataview() {
    dataview.setup(GlacierService.glacierInfosForSelected());
  }

  /**
   * Update the dataview.
   * @private
   */
  updateDataview() {
    dataview.update(GlacierService.glacierInfosForSelected());
  }
}

/* end Controller */

/* Singleton instance */
let controller = new Controller();

if (!(Object.assign instanceof Function)) {
  Object.assign = (target, ...sources) =>
    sources.forEach((source) =>
      Object.keys(source).forEach((key) => {
        target[key] = source[key];
      })
    );
}

/* Inject foreign implemented function into controller (they should be moved here though) */
controller.bridge = function (options) {
  Object.assign(bridge, options);
};

controller = new Proxy(controller, {
  get(controller, fn) {
    return function wrapped(...args) {
      printToConsole('Controller', fn, args);
      return controller[fn].apply(this, args);
    };
  },
});

/* Exports */

export default controller;
