import $ from 'jquery';
import { marked } from 'marked';
import * as DOMPurify from 'dompurify';

import { Downloader } from './Downloader';
import ChartService from './chart/ChartService';
import Translation from './Translation';

// -----
// constants

// paths on the server
export const PIC_BASE = '/geo/glacier_images'; // where glacier pictures live

// CSS selectors of factsheet blocks
const SEL_CITATION = '.fsQuotation';
const SEL_PHOTO = '.fsPhoto';

// Current language and translate function helper
const LANG = $('.factsheetGrid').attr('data-lang');
const translate = Translation('.factsheetGrid'); // translations are stored in factsheet.twig, from DB.

// -----
// variables

// -----
// helpers
const downloader = new Downloader('#downloadAllBtn');

/**
 * populates a content box or hides it
 */
const populate = function (selector, data, titleKey = undefined) {
  const box = $(selector);
  // empty content if any present
  box.empty();
  // add titles if required
  if (titleKey !== undefined) {
    box.append(`<h2 class="fsCommentTitle">${translate(titleKey)}</h2>`);
  }
  // swap content
  box.append(data);
  // hide if no contents
  // eslint-disable-next-line eqeqeq
  const contentcount = data && data.filter((d) => undefined != d).length;
  box.toggle(!!contentcount);
};

// -----
// Factsheet textual description blocks

/**
 * populates template description and citation/quotation node with data from per-glacier JSON
 * (also handles the case where there is multiple texts for current lang)
 */
const populateDescription = function (glacierInfo) {
  const allTexts = glacierInfo.texts ?? [];

  // eslint-disable-next-line eqeqeq
  const texts = allTexts.filter((d) => d.language == LANG)[0] || {};
  // add more easily accessible localizedTexts key & give entire glacierInfos to downloader
  glacierInfo.localizedTexts = texts;
  downloader.setMetadata(glacierInfo);

  // escape and format textual content
  const format = (str) => {
    // eslint-disable-next-line eqeqeq
    if (undefined == str) return str;
    // escape all malicious & half-open tags via DOMPurify and do a final cleanup via BrowserDOM
    // content is cleaned up because the amount of people who can write to the JSONs is not too limited
    const p = document.createElement('p');
    // this will render a version of the data without any potential XSS or similar due to DOMPurify
    p.innerHTML = DOMPurify.sanitize(str);
    // this will escape custom html tags and half-open tags especially
    const safeContent = p.textContent || p.innerText || '';
    // Run DOMPurify once more just to be sure nothing malicious somehow re-appeared from parsed Markdown
    const renderedMarkdown = DOMPurify.sanitize(
      marked.parseInline(safeContent)
    );

    // wrap everything in a <div> in a <p>
    return $('<div></div>').append($('<p></p>').append(renderedMarkdown));
  };

  // add descriptions which are automatically hidden if no content text is available
  populate('#cardDescription', format(texts['description']), 'description');
  populate(
    '#cardSpecialEvent',
    format(texts['special event']),
    'special event'
  );
  populate('#cardSources', format(texts['sources']), 'sources');

  // add citation/quotation
  populate(SEL_CITATION, format(texts.citation));
};

// -----
// Factsheet photo block (single lightbox-ish block)

/**
 * populates template picture collection with data from per-glacier JSON
 */
const populatePhotos = function (glacierInfo) {
  if (!glacierInfo.pictures) {
    // either something went wrong or this glacier doesn't have any photos
    populate(SEL_PHOTO, null);
    return;
  }

  const box = $(SEL_PHOTO);
  // cleanup
  if (box.data('lightGallery')) {
    box.data('lightGallery').destroy(true); // we'll put it on again at the end
  }

  // take only photos allowed to show up on factsheet
  // eslint-disable-next-line no-confusing-arrow
  const pics = glacierInfo.pictures.sort((p) =>
    p.isFactsheetPicture ? -1 : +1
  );
  const content = pics.map((pic, ix) => {
    const url = `${PIC_BASE}/${pic.filename}`;
    const gallery_attributes = { 'data-src': url, 'data-sub-html': pic.legend };
    // note: using .attr() escapes values
    // eslint-disable-next-line eqeqeq
    const thumb = 0 == ix ? $('<img />').attr('src', url) : '';
    return $('<div class="zoomItem"></div>')
      .attr(gallery_attributes)
      .append(thumb);
  });
  populate(SEL_PHOTO, content);

  // enable lightbox/gallery features
  box.filter('.imgGallery').lightGallery();
};

// -----
// Init

export const setup = function (feature, glacierInfo) {
  const chartBoxes = $('.fsChart');

  // Only show chart boxes when we have data
  chartBoxes.hide();

  const chartInstances = [];
  chartBoxes.each(function () {
    const box = $(this);
    const container = box.find('.js-chart');
    const options = container.data();
    options.showNames = false;

    // Box needs to be shown before the chart is generated so it gets
    // the right height.
    const chart = ChartService.update(
      container[0],
      [glacierInfo],
      options,
      () => box.show()
    );
    ChartService.updateDownloadControls(chart, false);
    chartInstances.push(chart);
  });

  downloader.setCharts(chartInstances);
  populateDescription(glacierInfo);
  populatePhotos(glacierInfo);
};
