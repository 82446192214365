import { bbox } from 'ol/loadingstrategy';
import Group from 'ol/layer/Group';
import Vector from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import LayerSwitcher from 'ol-ext/control/LayerSwitcher';
import Translation from '../Translation';
import {
  swissimage_wmts,
  swissalti3d_wmts,
  eiszeit_wmts,
  dufour_wmts,
  siegfried_wmts,
  pixelkarte_farbe_wmts,
  pixelkarte_grau_wmts,
  geltscher_maechtigkeit,
} from './layer/swisstopo_layer';
import {
  glamos_sgi_1850,
  glamos_sgi_1973,
  glamos_sgi_2016,
  glamos_sgi_2016_debris_cover,
} from './layer/glamos_layer';
import {
  noDataGlacierStyle,
  selectableStyleSmall_hasmass,
  selectableStyle_hassmass,
  selectableStyleSmall,
  selectableStyle,
  activeStyle,
} from './styles';

const switcherNode = $('.layerSwitcher').get(0);
const t = Translation(switcherNode);

// A group layer for base layers
const baseLayers = new Group({
  title: t('Hintergrundkarten'),
  openInLayerSwitcher: false,
  layers: [
    dufour_wmts,
    siegfried_wmts,
    eiszeit_wmts,
    swissimage_wmts,
    swissalti3d_wmts,
    pixelkarte_grau_wmts,
    pixelkarte_farbe_wmts,
  ],
});

const layer = baseLayers.getLayers().getArray();
for (let i = 0; i < layer.length; i += 1) {
  layer[i].setVisible(false);
}

const glamosSgi = new Group({
  title: t('Gletscherausdehnung'),
  openInLayerSwitcher: true,
  layers: [
    glamos_sgi_1850,
    glamos_sgi_1973,
    glamos_sgi_2016,
    glamos_sgi_2016_debris_cover,
  ],
});

const glamosSgiFactsheet = new Group({
  title: t('Gletscherausdehnung'),
  openInLayerSwitcher: true,
  layers: [glamos_sgi_1850, glamos_sgi_1973, glamos_sgi_2016],
});

const checkResolution_masse = function (feature, resolution) {
  if (resolution > 100) {
    return [selectableStyleSmall_hasmass];
  }

  return [selectableStyle_hassmass];
};

const checkResolution_laenge = function (feature, resolution) {
  if (resolution > 100) {
    return [selectableStyleSmall];
  }

  return [selectableStyle];
};

const gletscher_source_nodata = new Vector({
  strategy: bbox,
  id: 'pk_sgi',
});

const gletscher_source_haslength = new Vector({
  strategy: bbox,
  id: 'pk_sgi',
});

const gletscher_source_hasmass = new Vector({
  strategy: bbox,
  id: 'pk_sgi',
});

const gletscher_nodata = new VectorLayer({
  allwaysOnTop: true,
  title: t('ohne Messwerte'), // used as display name for layerswitcher
  source: gletscher_source_nodata,
  style: noDataGlacierStyle, // style different depending on data availibility
  layerSwitcherIcon: '/theme/img/pin-circle.png',
});

const gletscher_masse = new VectorLayer({
  title: t('Massenbilanz'), // used as display name for layerswitcher
  source: gletscher_source_hasmass,
  style: checkResolution_masse, // style different depending on data availibility
  layerSwitcherIcon: '/theme/img/pin-masse.png',
});

const gletscher_length = new VectorLayer({
  allwaysOnTop: true,
  title: t('Längenänderung'), // used as display name for layerswitcher
  source: gletscher_source_haslength,
  style: checkResolution_laenge, // style different depending on data availibility
  layerSwitcherIcon: '/theme/img/pin-default.png',
});

const GletscherLayers = new Group({
  title: t('Gletscher'),
  openInLayerSwitcher: true,
  layers: [gletscher_nodata, gletscher_length, gletscher_masse],
});

const selectedOverlay = new VectorLayer({
  source: new Vector(),
  style: activeStyle,
  displayInLayerSwitcher: false,
});

const switcher = new LayerSwitcher({
  target: switcherNode,
  reordering: false,
});

export {
  baseLayers,
  glamosSgi,
  glamosSgiFactsheet,
  gletscher_source_nodata,
  gletscher_source_haslength,
  gletscher_source_hasmass,
  gletscher_nodata,
  GletscherLayers,
  geltscher_maechtigkeit,
  selectedOverlay,
  switcher,
};
