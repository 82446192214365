import $ from 'jquery';
import Plotly from 'plotly.js';
import JSZip from 'jszip';

import {
  IMAGE_HEIGHT,
  IMAGE_WIDTH,
  IMAGE_SCALE,
} from './chart/ChartDownloadControls';
import { PIC_BASE } from './factsheetDetails';

export class Downloader {
  #button;
  #metadata = {};
  #charts = [];

  constructor(btnSelector) {
    this.#button = $(btnSelector);
    this.#button.on('click', this._downloadAll.bind(this));
  }

  #updateButton() {
    if (this.#charts && this.#metadata) {
      this.#button.prop('disabled', false);
    }
  }

  setMetadata(metadata) {
    this.#metadata = metadata;
    this.#updateButton();
  }

  setCharts(charts) {
    this.#charts = charts;
    this.#updateButton();
  }

  _downloadAll() {
    const filename = `${this.#metadata.shortName}.zip`;
    const originalZip = new JSZip();
    // create a secondary, but linked instance with shortname as root folder
    const zip = originalZip.folder(this.#metadata.shortName);

    // add citation txt
    zip.file(`citation.txt`, `${this.#metadata.localizedTexts.citation}\n`);
    // add raw (markdown) description
    zip.file(
      `description_${this.#metadata.shortName}.md`,
      `${this.#metadata.localizedTexts.description}\n`
    );
    // add (optional, if present) special events & sources markdown
    if (this.#metadata.localizedTexts['special event']) {
      zip.file(
        `special_event_${this.#metadata.shortName}.md`,
        `${this.#metadata.localizedTexts['special event']}\n`
      );
    }
    if (this.#metadata.localizedTexts.sources) {
      zip.file(
        `sources_${this.#metadata.shortName}.md`,
        `${this.#metadata.localizedTexts.sources}\n`
      );
    }

    // add all chart data
    for (const chart of this.#charts) {
      // charts with no data area also added but simply hidden
      if (chart.container.parentElement.style.display === 'none') continue;
      zip.file(
        `${chart.filename}.png`,
        Plotly.toImage(chart.container, {
          format: 'png',
          height: IMAGE_HEIGHT,
          width: IMAGE_WIDTH,
          scale: IMAGE_SCALE,
          imageDataOnly: true,
        }),
        { base64: true }
      );
      zip.file(
        `${chart.filename}.svg`,
        Plotly.toImage(chart.container, {
          format: 'svg',
          height: IMAGE_HEIGHT,
          width: IMAGE_WIDTH,
          scale: IMAGE_SCALE,
          imageDataOnly: true,
        }),
        { binary: false }
      );
      zip.file(`${chart.filename}.csv`, chart.toCsv());
    }

    // add glacier images (can add quite a bit of time & size to download).
    const imagePromises = [];
    for (let i = 0; i < this.#metadata.pictures.length; i++) {
      const picture = this.#metadata.pictures[i];
      imagePromises.push(
        $.ajax({
          url: `${PIC_BASE}/${picture.filename}`,
          method: 'GET',
          xhrFields: { responseType: 'arraybuffer' },
          error: () => {
            console.warn(`Failed downloading ${PIC_BASE}/${picture.filename}`);
          },
        })
      );
    }
    // wait for all image promises to be settled before adding only successful ones
    // this is done because jszip doesn't like a rejected promise to be added via file()
    Promise.allSettled(imagePromises)
      .then((results) => {
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          const picture = this.#metadata.pictures[i];

          if (
            result.status === 'rejected' &&
            process.env.NODE_ENV !== 'development'
          ) {
            throw new Error(
              `${picture.filename} failed to download! Cancelling zip generation.`
            );
          }

          if (result.status === 'fulfilled') {
            zip.file(picture.filename, result.value, {
              binary: true,
              comment: picture.legend,
            });
          }
        }
        return originalZip.generateAsync({ type: 'blob' });
      })
      .then((blob) => {
        // Create a URL for the zip file
        const url = URL.createObjectURL(blob);

        // Create a link element and set its href to the zip file URL
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        // Append the link element to the document body, click & remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Failed generating ZipFile', error));
  }
}
