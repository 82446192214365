import ChartService from './chart/ChartService';
import controller from './controller';

/** A dataview displays charts for multiple glaciers
 *
 * Tell it to setup() event handlers and graph library once.
 * It will monitor the selection of the #chart_param control
 * and tell controller when to switchChartType().
 *
 * You can tell it to update() and it will add or remove
 * data displayed in the chart.
 */
class Dataview {
  setup(glacierInfos) {
    // Only one of these is shown at a time, depending on select_id
    this.containers = $('.chartContainer .js-chart');

    // Keep chart instances indexed by source name
    this.charts = {};

    this.containers.each((_, container) => {
      const options = $(container).data();
      options.showNames = true;

      this.charts[container.id] = { container, options };
    });

    this.select_id = $('#chart_param');
    this.select_id.change(function () {
      controller.switchChartType();
    });
  }

  update(glacierInfos) {
    // HACK do nothing if we're called for the wrong page
    if (!this.select_id.length) return;

    const id = this.select_id.val();

    this.containers.hide();
    this.containers.filter(`[id="${id}"]`).show();

    const chart = this.charts[id];
    const chartInstance = ChartService.update(
      chart.container,
      glacierInfos,
      chart.options
    );
    ChartService.updateDownloadControls(chartInstance, true);
  }
}

export default new Dataview();
