import TileLayer from 'ol/layer/Tile';
import XYZLayer from 'ol/source/XYZ';
import Translation from '../../Translation';
const t = Translation('.layerSwitcher');
const LANG = $('.layerSwitcher').attr('data-lang');

const gletscher_maechtigkeit_wmts = new TileLayer({
  source: new XYZLayer({
    url: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.geologie-gletschermaechtigkeit/default/current/3857/{z}/{x}/{y}.png',
    attributions:
      '© <a target="new" href="https://www.swisstopo.admin.ch/">swisstopo</a>',
  }),
  baseLayer: false,
  title: t('Gletschermächtigkeit'),
  visible: false,
  opacity: 0.7,
  layerSwitcherCustomImage: `/theme/img/maechtigkeit_legend_${LANG}.png`,
});

export default gletscher_maechtigkeit_wmts;
