import Plotly from 'plotly.js';

// unicode U+202F NARROW NO-BREAK SPACE
const NNBSP = ' ';

/**
 * The chart labels, ticks and legend font size.
 *
 * @constant {number}
 */
const LABEL_FONT_SIZE = 10;

/**
 * The chart model.
 */
export default class Chart {
  /**
   * Create an instance of Chart.
   *
   * @param {HTMLElement} container
   * @param {GlacierInfo[]} glacierInfos
   * @param {Object} options
   * @param {string} options.legend
   * @param {string} options.source
   * @param {string} options.sourceCsv
   * @param {boolean} options.isCumulative
   * @param {boolean} options.shouldRoundValues
   * @param {boolean} options.hasFixedXAxis
   * @param {string} options.unit
   * @param {boolean} options.showNames
   * @param {Object} options.translations
   * @param {string} options.lang
   */
  constructor(container, glacierInfos, options) {
    this.container = container;
    this.glacierInfos = glacierInfos === undefined ? [] : glacierInfos;
    this.options = options === undefined ? {} : options;
    this.valueKey = options.isCumulative ? 'variationCumulative' : 'variation';
    this.filename = this.createFilename();
    this.plotData = this.createPlotData();
    this.plotLayout = this.createPlotLayout();
    this.plotOptions = {
      displaylogo: false,
      displayModeBar: true,
      responsive: true,
      modeBarButtonsToRemove: [
        'autoScale2d',
        'hoverCompareCartesian',
        'hoverClosestCartesian',
        'lasso2d',
        // 'pan2d',
        // 'resetScale2d',
        'select2d',
        'toggleSpikelines',
        'toImage',
        // 'zoom2d',
        'zoomIn2d',
        'zoomOut2d',
      ],
      scrollZoom: true,
    };
    if (this.plotData && this.plotData[0] && this.plotData[0].mode === 'bar') {
      this.plotLayout.yaxis.fixedrange = false;
    }
    this.rangeXAxis = null;
  }

  /**
   * Draw (generate/update) the chart.
   *
   * @public
   * @param {Function} beforeShow
   */
  async draw(beforeShow) {
    const plotlyEl = $(this.container).find('.plotly');
    const noDataEl = $(this.container).find('.outputChart__nodata');

    if (this.plotData.length === 0) {
      noDataEl.show();
      plotlyEl.hide();
      return;
    }

    noDataEl.hide();
    plotlyEl.show();

    if (beforeShow) beforeShow();
    try {
      await Plotly.react(
        this.container,
        this.plotData,
        this.plotLayout,
        this.plotOptions
      );
    } catch (err) {
      throw new Error(err);
    }

    // Set the x-axis range whenever it changes (e.g. through zooming/paning).
    // Unfortunately Plotly.js has two ways of passing the xaxis.range, so we have to
    // cover both cases. If there is no range, just reset it to "null".
    this.container.on('plotly_relayout', (event) => {
      if (event['xaxis.range']) {
        this.rangeXAxis = event['xaxis.range'];
      } else if (event['xaxis.range[0]']) {
        this.rangeXAxis = [event['xaxis.range[0]'], event['xaxis.range[1]']];
      } else {
        this.rangeXAxis = null;
      }
    });
  }

  /**
   * Convert the plot data to CSV file contents.
   *
   * @public
   * @returns {string} The CSV file contents.
   */
  toCsv() {
    // Get translated citation text from first glacier info (it's the same for all).
    const citationInfo = this.glacierInfos[0].texts.find(
      (t) => t.language === this.options.lang
    ).citation;

    // Get one row for each measurement and glacier.
    const dataRows = this.glacierInfos
      .map((glacierInfo) => {
        const measurements = glacierInfo[this.options.sourceCsv];
        let dataRow;
        if (measurements) {
          dataRow = measurements
            .sort((a, b) => b.yearTo - a.yearTo)
            .reverse()
            .map((measurement) => [
              glacierInfo.fullName,
              glacierInfo.pkSgi,
              measurement.dateFrom,
              measurement.dateTo,
              measurement[this.valueKey],
            ]);
        } else {
          dataRow = [];
        }
        return dataRow;
      })
      .reduce((acc, cur) => acc.concat(cur), []);

    // Assemble the CSV rows.
    const rows = [
      [`"${citationInfo}"`],
      [this.options.legendCsv],
      [
        'Glacier name',
        'Glacier ID',
        'Start date of observation',
        'End date of observation',
        'Value',
      ].map((key) => this.options.translations[key]),
      ...dataRows,
    ];

    // Convert to text.
    // Cells are delimited by commas, rows by newlines.
    return rows.map((row) => row.join(',')).join('\n');
  }

  /**
   * Create a filename for the chart, using it's container id and the glaciers short
   * names.
   *
   * @private
   * @returns {string} The chart filename.
   */
  createFilename() {
    return [
      this.container.id,
      ...this.glacierInfos.map((glacierInfo) => glacierInfo.shortName),
    ].join('_');
  }

  /**
   * Create the plot data object.
   *
   * @private
   */
  createPlotData() {
    const chartType = this.options.isCumulative ? 'lines+markers' : 'bar';

    return this.glacierInfos
      .filter((glacierInfo) => glacierInfo[this.options.source] !== null)
      .map((glacierInfo) => {
        const dataSorted = glacierInfo[this.options.source]
          // Sort ascending by endpoint of measuring period.
          .sort((firstEl, secondEl) => {
            return firstEl.yearTo - secondEl.yearTo;
          });

        let data;
        if (this.options.isCumulative) {
          // If the chart displays cumulative data, extend the data with the
          // necessary filling values for correctly displaying the data gaps.
          data = dataSorted.reduce((acc, cur, index) => {
            let add = [cur];

            // Compare current datapoint with the last one, to see whether we
            // have a gap or not.
            const last = acc[acc.length - 1];
            if (last && last.yearTo !== cur.yearFrom) {
              // If there is a gap, prepend a null valued point (needed to create
              // a gap in the plot) and a gap end point (using the same value as
              // the gap start) to the data which is to be added.
              add = [
                {
                  [this.valueKey]: null,
                  yearTo: last.yearTo + 1,
                },
                Object.assign({}, last, {
                  yearFrom: null,
                  yearTo: cur.yearFrom,
                }),
                ...add,
              ];
            }

            // Prepend a first set of data
            // with 0 as the value.
            if (index === 0) {
              add = [{ [this.valueKey]: 0, yearTo: cur.yearFrom }, ...add];
            }

            return [...acc, ...add];
          }, []);
        } else {
          data = dataSorted;
        }

        // Map the data for the x (years) and y (values) axis.
        const years = data.map((e) => e.yearTo);
        const values = data.map((e) => {
          let value = e[this.valueKey];
          if (value !== null && this.options.shouldRoundValues) {
            value = Math.round(value);
          }
          return value;
        });

        const text = data.map((e) => {
          const yearFrom = this.options.isCumulative ? years[0] : e.yearFrom;
          return yearFrom !== null
            ? `${yearFrom} - ${e.yearTo}`
            : `${e.yearTo}`;
        });

        return {
          text,
          x: years,
          y: values,
          mode: chartType,
          type: chartType.includes('bar') ? chartType : 'scatter',
          name: glacierInfo.fullName,
          id: glacierInfo.uuid,
          hovertemplate: `%{y}${NNBSP}${this.options.unit}<br>%{text}`,
          showlegend: Boolean(this.options.showNames),
          marker: { size: 5 },
          line: { width: 1 },
        };
      });
  }

  /**
   * Create the plot layout config.
   *
   * @private
   */
  createPlotLayout() {
    let rangeXAxis = [];
    if (this.options.hasFixedXAxis) {
      // Calculate the chart's glacier infos combined year range.
      const yearRange = { from: null, to: null };
      this.glacierInfos.forEach((glacierInfo) => {
        if (!yearRange.from || glacierInfo.yearRange.from < yearRange.from) {
          yearRange.from = glacierInfo.yearRange.from;
        }
        if (!yearRange.to || glacierInfo.yearRange.to > yearRange.to) {
          yearRange.to = glacierInfo.yearRange.to;
        }
      });

      // Set the x axis range by taking the year range and adding an additional
      // offset of years on each side, so the chart get's displayed nicely.
      const rangeXAxisOffset = 3;
      rangeXAxis = [
        yearRange.from - rangeXAxisOffset,
        yearRange.to + rangeXAxisOffset,
      ];
    }

    return {
      height: 300,
      barmode: 'group',
      uirevision: 'true',
      dragmode: 'pan',
      hovermode: 'closest',
      xaxis: {
        range: rangeXAxis,
        showgrid: true,
        tickfont: {
          size: LABEL_FONT_SIZE,
        },
      },
      yaxis: {
        autorange: true,
        fixedrange: true,
        automargin: true,
        title: {
          text: this.options.legend,
          font: {
            size: LABEL_FONT_SIZE,
          },
        },
        tickfont: {
          size: LABEL_FONT_SIZE,
        },
      },
      margin: {
        l: 50,
        t: 25,
        b: 30,
        r: 20,
      },
      colorway: ['#45cadd', '#a52a2a', '#e6bc5a', '#70c67e', '#9d6ccc'],
      legend: {
        y: -0.25,
        font: {
          size: LABEL_FONT_SIZE,
          family: '"Open Sans", verdana, arial, sans-serif',
        },
        orientation: 'h',
        xanchor: 'center',
        x: 0.5,
        itemsizing: 'constant',
      },
    };
  }
}
