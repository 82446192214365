import Plotly from 'plotly.js';

const CONTROLS_SELECTOR = '.js-chart-download';

/**
 * The height of the image download in px.
 *
 * @constant {number}
 */
export const IMAGE_HEIGHT = 300;

/**
 * The width of the image download in px.
 *
 * @constant {number}
 */
export const IMAGE_WIDTH = 700;

/**
 * The factor to scale the image download by.
 *
 * @constant {number}
 */
export const IMAGE_SCALE = 5;

/**
 * Stateful model for the chart download controls.
 */
export default class ChartDownloadControls {
  /**
   * Create an instance of ChartDownloadControls.
   *
   * @param {Chart} chart The chart to be downloaded.
   * @param {boolean} isGlobal Whether the controls are global (for all charts).
   */
  constructor(chart, isGlobal) {
    this.chart = chart;
    this.isGlobal = isGlobal;
    this.elDownloadControls = this.getElDownloadControls();
    this.elDownloadFormat = this.getElDownloadFormat();

    // Check if the used browser is IE or a non-chromium based version of
    // Edge the same way Plotly.js does. If it is, disable the png download
    // as it's not supported by plotly due to compability issues concerning
    // the transformation of svg to png.
    // https://github.com/plotly/plotly.js/blob/master/src/lib/index.js#L700-L702
    // https://github.com/plotly/plotly.js/issues/2077
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      const optionPng = this.elDownloadFormat.querySelector(
        'option[value="png"]'
      );
      if (optionPng) {
        optionPng.selected = false;
        optionPng.disabled = true;
        optionPng.title = 'PNG download is not supported by your browser.';
      }

      // Fallback to SVG download.
      const optionSvg = this.elDownloadFormat.querySelector([
        'option[value="svg"]',
      ]);
      if (optionSvg) {
        optionSvg.selected = true;
      }
    }

    this.format = this.elDownloadFormat.value;
    this.elDownloadButton = this.getElDownloadButton();
  }

  /**
   * Update the controls' chart id data attribute.
   *
   * @public
   */
  updateChartId() {
    this.elDownloadControls.setAttribute(
      'data-chart-id',
      this.chart.container.id
    );
  }

  /**
   * Update the controls' event listeners.
   *
   * @public
   */
  updateEventListeners() {
    this.addDownloadButtonEventListeners();
    this.addDownloadFormatEventListeners();
  }

  /**
   * Get the chart download controls element.
   *
   * @private
   * @returns {HTMLDivElement} The chart download controls element.
   */
  getElDownloadControls() {
    const elDownloadControls = document.querySelector(
      this.isGlobal
        ? CONTROLS_SELECTOR
        : `${CONTROLS_SELECTOR}[data-chart-id='${this.chart.container.id}']`
    );
    if (!elDownloadControls) {
      throw new Error('Could not find chart download controls');
    }
    return elDownloadControls;
  }

  /**
   * Get the chart download format select element.
   *
   * @private
   * @return {HTMLSelectElement} The chart download format select element.
   */
  getElDownloadFormat() {
    const elDownloadFormat = this.elDownloadControls.querySelector('select');
    if (!elDownloadFormat) {
      throw new Error('Could not find chart download filetype select.');
    }
    return elDownloadFormat;
  }

  /**
   * Get the chart download button element.
   *
   * @private
   * @returns {HTMLButtonElement} The chart download button element.
   */
  getElDownloadButton() {
    const elDownloadButton = this.elDownloadControls.querySelector('button');
    if (!elDownloadButton) {
      throw new Error('Could not find chart download button.');
    }
    return elDownloadButton;
  }

  /**
   * Add the event listeners for the download button element.
   *
   * @private
   */
  addDownloadButtonEventListeners() {
    this.elDownloadButton.onclick = () => {
      this.format === 'csv' ? this.downloadCsv() : this.downloadImage();
    };
  }

  /**
   * Add the event listeners for the download format select element.
   *
   * @private
   */
  addDownloadFormatEventListeners() {
    this.elDownloadFormat.onchange = (event) => {
      this.format = event.target.value;
    };
  }

  /**
   * Download chart as CSV file.
   *
   * @private
   */
  downloadCsv() {
    const link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent(this.chart.toCsv())
    );
    link.setAttribute('download', `${this.chart.filename}.csv`);
    this.elDownloadControls.appendChild(link);
    link.click();
    this.elDownloadControls.removeChild(link);
  }

  /**
   * Download the chart as image (png or svg).
   *
   * @private
   */
  downloadImage() {
    Plotly.downloadImage(this.chart.container, {
      format: this.format,
      filename: this.chart.filename,
      height: IMAGE_HEIGHT,
      width: IMAGE_WIDTH,
      scale: IMAGE_SCALE,
    });
  }
}
