import Circle from 'ol/style/Circle';
import { Icon, Style, Fill, Stroke } from 'ol/style';

const circleStroke = new Stroke({
  color: 'white',
  width: 2,
});

const hidePoints = new Style({
  image: new Circle({
    radius: 0,
    fill: new Fill({
      color: 'black',
    }),
  }),
});

const noDataGlacierStyle = new Style({
  image: new Circle({
    radius: 4,
    fill: new Fill({
      color: 'black',
    }),
    stroke: circleStroke,
  }),
});

const selectableStyleSmall = new Style({
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#3f853d',
      stroke: '#380303',
      zIndex: 50,
    }),
    stroke: circleStroke,
  }),
});

const selectableStyleSmall_hasmass = new Style({
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#2b7bb9',
      stroke: '#2b7bb9',
      zIndex: 0,
    }),
    stroke: circleStroke,
  }),
});

const hoverStyleSmall = new Style({
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: '#380303',
      stroke: '#380303',
      zIndex: 100,
    }),
    stroke: circleStroke,
  }),
});

const defaultGlacierStyle = new Style({
  image: new Circle({
    radius: 5,
    fill: new Fill({
      color: 'red',
    }),
    stroke: circleStroke,
  }),
});

const hoverStyle = new Style({
  image: new Icon({
    src: '/theme/img/pin-hover.svg',
    scale: 0.7,
    zIndex: 100,
  }),
});

const selectableStyle = new Style({
  image: new Icon({
    src: '/theme/img/pin-default.svg',
    scale: 0.7,
  }),
});

const selectableStyle_hassmass = new Style({
  image: new Icon({
    src: '/theme/img/pin-masse.svg',
    scale: 0.7,
  }),
});

const activeStyle = new Style({
  image: new Icon({
    src: '/theme/img/pin-active.svg',
    scale: 0.7,
    zIndex: 1000,
  }),
});

export {
  hidePoints,
  noDataGlacierStyle,
  selectableStyleSmall,
  selectableStyleSmall_hasmass,
  hoverStyleSmall,
  defaultGlacierStyle,
  hoverStyle,
  selectableStyle,
  selectableStyle_hassmass,
  activeStyle,
};
